import { useContext } from "react";
import { Navbar, Container, Nav, Button } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { AppContext } from "../providers/AppProvider";

export default function Header() {
  const navigate = useNavigate();

  const app = useContext(AppContext);

  const handleNavigate = (route?: string) => () => {
    const toggler = document.querySelector<HTMLButtonElement>(".navbar-toggler");

    if(!toggler?.classList.contains("collapsed")) {
      toggler?.click();
    }

    if (route) {
      navigate(route);
    }
  };

  const handleLogout = () => {
    app.logout();
    handleNavigate("/login")();
  };

  return (
    <header>
      <Navbar id="main-navbar" expand="lg" fixed="top" className="py-4">
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse className="justify-content-between">
            <Nav className="justify-content-between w-100 mt-3 mt-lg-0">
              <NavLink
                onClick={handleNavigate()}
                className="nav-link text-center text-lg-start py-3 py-lg-0"
                to="/"
              >
                INÍCIO
              </NavLink>
              <NavLink
                onClick={handleNavigate()}
                className="nav-link text-center text-lg-start py-3 py-lg-0"
                to="/como-participar"
              >
                COMO PARTICIPAR
              </NavLink>
              <NavLink
                onClick={handleNavigate()}
                className="nav-link text-center text-lg-start py-3 py-lg-0"
                to="/regulamento"
              >
                REGULAMENTO
              </NavLink>
              <NavLink
                onClick={handleNavigate()}
                className="nav-link text-center text-lg-start py-3 py-lg-0"
                to="/duvidas"
              >
                DÚVIDAS
              </NavLink>
              <NavLink
                onClick={handleNavigate()}
                className="nav-link text-center text-lg-start py-3 py-lg-0"
                to="/fale-conosco"
              >
                FALE CONOSCO
              </NavLink>
            </Nav>
            {!app.isAuthenticated && (
              <Nav className="ms-0 ms-lg-5 d-flex flex-row mt-5 mt-lg-0 align-items-center justify-content-center">
                <Nav.Item className="me-2">
                  <Button
                    className="button"
                    variant="primary"
                    size="sm"
                    onClick={handleNavigate("/ganhadores")}
                  >
                    GANHADORES
                  </Button>
                </Nav.Item>
                <Nav.Item className="me-2">
                  <Button
                    className="button"
                    variant="primary"
                    size="sm"
                    onClick={handleNavigate("/login")}
                  >
                    LOGIN
                  </Button>
                </Nav.Item>
                <Nav.Item>
                  <Button
                    className="button"
                    variant="outline-primary"
                    size="sm"
                    onClick={handleNavigate("/cadastrar")}
                  >
                    CADASTRAR
                  </Button>
                </Nav.Item>
              </Nav>
            )}

            {app.isAuthenticated && (
              <Nav className="ms-0 ms-lg-5 d-flex flex-row mt-5 mt-lg-0 align-items-center justify-content-center">
                <Nav.Item className="me-2">
                  <Button
                    className="button"
                    variant="primary"
                    size="sm"
                    onClick={handleNavigate("/ganhadores")}
                  >
                    GANHADORES
                  </Button>
                </Nav.Item>
                <Nav.Item className="me-2">
                  <Button
                    className="button"
                    variant="outline-primary"
                    size="sm"
                    onClick={handleNavigate("/meu-extrato")}
                  >
                    MEU EXTRATO
                  </Button>
                </Nav.Item>
                <Nav.Item>
                  <Button
                    className="button"
                    variant="outline-primary"
                    size="sm"
                    onClick={handleLogout}
                  >
                    SAIR
                  </Button>
                </Nav.Item>
              </Nav>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}
