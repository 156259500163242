import { Container, Row, Col, Stack, Image } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import { useEffect } from "react";
import cookieConfig from "../configs/cookieConfig";
import { AbrilImbativelLogo, CarrefourLogo } from "./Images";

export default function Footer() {
  useEffect(() => {
    CookieConsent.run(cookieConfig);
  }, []);

  return (
    <footer>
      <Container>
        <Row>
          <Col xs={12}>
            <Row>
              <Col xs={12} sm={4} md={2} className="mb-4">
                <NavLink className="text-lg-start" to="/politica-privacidade">
                  Política de privacidade
                </NavLink>
              </Col>
              <Col xs={12} sm={4} md={2} className="mb-4">
                <NavLink to="/regulamento">Regulamento</NavLink>
              </Col>
              <Col xs={12} sm={4} md={2} className="mb-4">
                <a
                  href="#"
                  role="button"
                  className="mx-auto"
                  onClick={() => CookieConsent.showPreferences()}
                >
                  Definições de cookies
                </a>
              </Col>
            </Row>
          </Col>

          <Col xs={12} md={7}>
            <p className="text-start">
              Imagens meramente ilustrativas. Período de participação de
              02/04/2024 a 29/04/2024. Consulte as condições de participação,
              regulamento completo, formatos de premiação e número de
              autorização no site www.carrinhocheiooanointeiro.com.br * Prêmios
              entregues em formato de cartão virtual ** Prêmios entregues em
              formato de descontos. CERTIFICADO DE AUTORIZAÇÃO SPA/ME Nº
              04.032887/2024
            </p>
          </Col>

          <Col xs={12} md={5}>
            <Stack direction="horizontal" className="justify-content-around">
              <a
                title="Abril Imbatível Carrefour"
                href="https://carrinhocheiooanointeiro.com.br/?&utm_source=carrinho-cheio&utm_campaign=abril-imbativel&utm_term=banner-site"
              >
                <Image
                  src={AbrilImbativelLogo}
                  alt="Logo Abril Imbatível Carrefour"
                  height={80}
                />
              </a>
              <Image
                src={CarrefourLogo}
                alt="Logo Carrefour"
                width={140}
                fluid
              />
            </Stack>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
