import axios from "axios";
import { Response } from "../services/api";
import {
  ILoginRequest,
  ILoginResponse,
  IRecoveryRequest,
  IRecoveryResponse,
  IRegisterCupomRequest,
  IRegisterCupomResponse,
  IRegisterDocumentsRequest,
  IRegisterDocumentsResponse,
  IRegisterRequest,
  IRegisterResponse,
  IUserResponse,
} from "../interfaces";
import { useMemo } from "react";
import storage from "../storage";

const api = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
});

api.interceptors.request.use((request) => {
  const accessToken = storage.getItem("access_token") ?? "";
  const ipAddress = storage.getItem("ip_address") ?? "";

  request.headers.APP_CLIENT_IP = `${ipAddress}`;

  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`;
  }

  return request;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      storage.removeItem("access_token");
      if (!window.location.href.includes("/login")) {
        window.location.href = "/login";
      }
    }
    
    return Response.from(error);
  }
);

export default function useApi() {
  const login = async (data: ILoginRequest) => {
    try {
      const response = await api.post<ILoginResponse>("/login", data);
      return Response.from<ILoginResponse>(response);
    } catch (error: any) {
      return Response.fromError(error);
    }
  };

  const register = async (data: IRegisterRequest) => {
    try {
      const response = await api.post<IRegisterResponse>("/cadastro", data);
      return Response.from<IRegisterResponse>(response);
    } catch (error: any) {
      return Response.fromError(error);
    }
  };

  const recovery = async (data: IRecoveryRequest) => {
    try {
      const response = await api.post("/recuperar-senha", data);
      return Response.from<IRecoveryResponse>(response);
    } catch (error: any) {
      return Response.fromError(error);
    }
  };

  const activate = async (data: any) => {
    try {
      const response = await api.post("/ativar-cadastro", data);
      return Response.from(response);
    } catch (error: any) {
      return Response.fromError(error);
    }
  };

  const activationCheck = async (data: any) => {
    try {
      const response = await api.post("/check-ativacao", data);
      return Response.from(response);
    } catch (error: any) {
      return Response.fromError(error);
    }
  };

  const logout = async () => {
    try {
      const response = await api.post("/logout");
      return Response.from(response);
    } catch (error: any) {
      return Response.fromError(error);
    }
  };

  const getNumbers = async () => {
    try {
      const response = await api.get("/meus-numeros");
      return Response.from(response);
    } catch (error: any) {
      return Response.fromError(error);
    }
  };

  const getDiscounts = async () => {
    try {
      const response = await api.get("/meus-descontos");
      return Response.from(response);
    } catch (error: any) {
      return Response.fromError(error);
    }
  };

  const getWinners = async () => {
    try {
      const response = await api.get("/ganhadores");
      return Response.from(response);
    } catch (error: any) {
      return Response.fromError(error);
    }
  };

  const getUser = async () => {
    try {
      const response = await api.get<IUserResponse>("/usuario");
      return Response.from<IUserResponse>(response);
    } catch (error: any) {
      return Response.fromError(error);
    }
  };

  const registerCupom = async (data: IRegisterCupomRequest) => {
    try {
      const response = await api.post<IRegisterCupomResponse>("/participar", data);
      return Response.from<IRegisterCupomResponse>(response);
    } catch (error: any) {
      return Response.fromError(error);
    }
  };

  const getParticipationLimit = async () => {
    try {
      const response = await api.post("/check-limite");
      return Response.from(response);
    } catch (error: any) {
      return Response.fromError(error);
    }
  }

  const registerDocuments = async (data: IRegisterDocumentsRequest) => {
    try {
      const response = await api.post<IRegisterDocumentsResponse>("/documentos", data);
      return Response.from<IRegisterDocumentsResponse>(response);
    } catch (error: any) {
      return Response.fromError(error);
    }
  };

  const spinRoutette = async (couponId: string) => {
    try {
      const response = await api.post(`/girar-roleta/${couponId}`);
      return Response.from(response);
    } catch (error: any) {
      return Response.fromError(error);
    }
  };

  const contact = async (data: any) => {
    try {
      const response = await api.post("/contato", data);
      return Response.from(response);
    } catch (error: any) {
      return Response.fromError(error);
    }
  };

  const getIP = async () => {
    try {
      const response = await axios.get("https://api.ipify.org?format=json");
      return response;
    } catch (error: any) {
      return Response.fromError(error);
    }
  }

  return useMemo(
    () => ({
      login,
      register,
      logout,
      recovery,
      activate,
      getNumbers,
      getWinners,
      getDiscounts,
      getUser,
      registerCupom,
      registerDocuments,
      spinRoutette,
      contact,
      getIP,
      activationCheck,
      getParticipationLimit,
    }),
    []
  );
}
