import Header from "../components/Header";
import Footer from "../components/Footer";
import { BgImage } from "../components/Images";
import { Outlet, useLocation } from "react-router-dom";
import { useMemo, useEffect, Suspense } from "react";
import useApi from "../hooks/useApi";
import routes from "../configs/routes";
import storage from "../storage";

export default function RootLayout() {
  const location = useLocation();
  const pathname = location.pathname;

  const route = useMemo(
    () =>
      routes.find(
        (route) => pathname.startsWith(route.path) && route.path !== "/",
      ) ?? routes[0],
    [pathname],
  );

  const api = useApi();

  useEffect(() => {
    if (route) {
      const section =
        route.path === "/como-participar" ? "como-participar" : "";

      if (section) {
        const $section = document.querySelector(`#${section}`);
        $section?.scrollIntoView({ behavior: "smooth", block: "nearest" });
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      window.scrollTo(0, 0);
    }

    document.title = `${route?.title} - Promoção Carrinho Cheio o Ano Inteiro`;
  }, [route]);

  useEffect(() => {
    api
      .getIP()
      .then((response) => {
        try {
          storage.setItem("ip_address", response.data.ip, false);
        } catch (err) {
          console.log(err);
        }
      })
      .catch((err) => {
        console.log(err);
        storage.setItem("ip_address", "");
      });
  }, [api]);

  return (
    <>
      <Header />
      <main
        style={{
          backgroundImage: `image-set(
            url(${BgImage}) type("image/webp"),
            url(../assets/images/bg.png) type("image/jpeg")`,
        }}
      >
        <Suspense key={location.key} fallback={<h1>Carregando...</h1>}>
          <Outlet />
        </Suspense>
      </main>
      <Footer />
    </>
  );
}
