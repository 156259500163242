import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";
import RootLayout from "./layouts/RootLayout";

export const router = createBrowserRouter(createRoutesFromElements(
  <Route path="/" element={<RootLayout />}>
    <Route lazy={() => import("./pages/HomePage")} index />
    <Route path="/como-participar" lazy={() => import("./pages/HomePage")} />
    <Route path="/regulamento" lazy={() => import("./pages/RegulationPage")} />
    <Route path="/ganhadores" lazy={() => import("./pages/WinnersPage")} />
    <Route path="/login" lazy={() => import("./pages/LoginPage")} />
    <Route path="/cadastrar" lazy={() => import("./pages/RegisterPage")} />
    <Route path="/roleta/:id" lazy={() => import("./pages/RoulettePage")} />
    <Route path="/meu-extrato" lazy={() => import("./pages/MyExtractPage")} />
    <Route path="/meus-numeros" lazy={() => import("./pages/CouponsPage")} />
    <Route path="/meus-descontos" lazy={() => import("./pages/DiscountsPage")} />
    <Route path="/politica-privacidade" lazy={() => import("./pages/PrivacyPolicyPage")} />
    <Route path="/duvidas" lazy={() => import("./pages/FAQPage")} />
    <Route path="/cupom" lazy={() => import("./pages/CouponRegisterPage")} />
    <Route path="/esqueci-minha-senha" lazy={() => import("./pages/PasswordRecoveryPage")} />
    <Route path="/fale-conosco" lazy={() => import("./pages/ContactPage")} />
    <Route path="/ativar-cadastro" lazy={() => import("./pages/ProfileActivation")} />
    <Route path="*" lazy={() => import("./pages/HomePage")} />
  </Route>
));
